import { transportation } from '@/axios'

export default {
  uploadRequiredDocsFiles (payload) {
    return transportation().patch('drivers', payload)
  },
  getRequirementsDocuments () {
    return transportation().get('required-docs?filters[type]=driver')
  },
  postRequirementsDocuments (payload) {
    return transportation().post('drivers/doc', payload)
  },
  getMerchantInfo () {
    return transportation().get('drivers/info')
  }
}
